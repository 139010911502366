<template>
  <div class="mTopic">
    <div class="mTopic_title">方案专题</div>
    <div class="mTopic_box">
      <div class="mTopic_boxItem" v-for="(item,index) in topicList" :key="index" @click="openTopic(item.id)">
        <div class="mTopic_boxItem_rightImg">
          <img :src="item.cover" />
        </div>
        <div class="mTopic_boxItem_rightNum">
          <div class="mTopicOmit">{{item.title}}</div>
          <div>共有{{item.num}}作品</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topicList: {},
      currentPage: 1,
    };
  },
  created() {
    this.getTopicList();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true); // 离开页面移除滚动加载事件
  },
  methods: {
    getTopicList() {
      this.$axios({
        url: this.$api + "/auth/ppt/album/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.topicList = res.data.data.res;
        }
      });
    },
    openTopic(e) {
      this.$router.push({
        path: "/mTopicDetails",
        query: {
          id: e,
        },
      });
    },
    // 处理滚动加载的数据
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop; //滚动条的高
      let screenHeight = window.screen.availHeight; //当前屏幕的高
      let documentTop = document.body.scrollHeight; //全部内容的高
      if (scrollTop + screenHeight >= documentTop + 70) {
        this.$axios({
          url: this.$api + "/auth/ppt/album/list",
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("user_token"),
            "Content-Type": "application/json;charset=UTF-8;",
          },
          params: {
            page: (this.currentPage += 1),
          },
        }).then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data.res;
            let listArr = this.topicList;
            listArr.push.apply(listArr, list);
            this.topicList = listArr;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.mTopic {
  margin-bottom: 70px;
}
.mTopicOmit {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mTopic_title {
  padding: 3% 0;
  color: #000;
  text-align: center;
  background-color: #fdd30f;
}
.mTopic_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mTopic_boxItem {
  margin-top: 3%;
  width: 100%;
  position: relative;
}
.mTopic_boxItem_rightImg {
  width: 100%;
  height: 10rem;
}
.mTopic_boxItem_rightImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mTopic_boxItem_rightNum {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.5rem;
  background-color: #00000041;
  color: #fff;
  font-size: 14px;
  padding: 5%;
  bottom: 0;
  box-sizing: border-box;
}
</style>